<template>
  <div>
    <el-row style="text-align: center">
      <el-form :inline="true" :model="form" label-width="60px">
        <el-form-item label>
          <el-select v-model="form.county" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label>
          <el-select v-model="form.county" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label>
          <el-select v-model="form.county" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label>
          <el-input
            v-model="form.name"
            placeholder="考试名称"
            @input="init"
          ></el-input>
        </el-form-item>

        <el-button v-throttle type="primary" icon="el-icon-search"
          >搜索</el-button
        >
      </el-form>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-button
        v-throttle
        type="danger"
        icon="el-icon-delete"
        size="medium"
        style="float: left"
        @click="batchRemove"
        >批量删除</el-button
      >
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-refresh-right"
        style="float: right"
        @click="refresh"
        >刷新</el-button
      >
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        stripe
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="nickName"
          label="考试名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="subject"
          label="学科"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="创建时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="学校"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="教室"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="座位号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="状态"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="告警类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="描述"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="视频位置"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" min-width="160px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="edit(scope.row, '查看')"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'warnManage',
  data() {
    return {
      form: {
        county: '',
        name: '',
      },
      //分页
      page: {
        pageIndex: 1,
        pagSize: 10,
        totalSize: 0,
      },
      loading: false,
      list: [{ nickName: '123', subject: '生物' }],
      selectedList: [],
      load: '',
      serviceLoading: '',
      serviceLoadingText: '',
    }
  },
  mounted() {},
  methods: {
    init() {},
    //当选择项发生变化时会触发
    handleSelectionChange(val) {
      this.selectedList = val
    },
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      // this.loading = true;
      this.page.pageIndex = val
      this.init()
    },
    //pageSize 改变时会触发;如每页10条切换到每页15条
    handleSizeChange(val) {
      // this.loading = true;
      this.page.pagSize = val
      this.init()
    },
    batchRemove() {
      if (this.selectedList <= 0) {
        this.msgWarning('请选择要删除的数据')
        return
      }
      console.log(this.selectedList)
      // const box = .;
      this.globalMessageBox(
        '此操作将永久删除该文件, 是否继续?',
        '提示',
        '确认',
        '取消',
        'warning'
      )
        .then(() => {})
        .catch(() => {})
      // this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
      //   confirmButtonText: "确定",s
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     const loading = this.globalLoading('正在删除...');
      //     setTimeout(() => {
      //       loading.close();
      //       this.msgSuccess("删除成功");
      //     }, 2000);
      //   })
      //   .catch(() => { });
    },
    refresh() {},
    edit() {},
  },
  watch: {},
}
</script>
<style></style>
